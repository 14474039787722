<template>
  <div v-can="'RepMedRel1'">
    <section class="header">
      <div class="title">
        Relatório de repasse
        <span id="help-icon" class="icon-box"><HelpCircle class="icon stroke" /></span>
        <b-tooltip target="help-icon" placement="bottom">
          Gerencie o repasse pelo relatório de repasse dos favorecidos configurados.
        </b-tooltip>
      </div>
    </section>


    <b-row v-if="!beneficiary" class="select-beneficiary">
      <SimpleBeneficiarySelect
        v-model="beneficiary"
        :options="beneficiaries"
        :validated="true"
      />
    </b-row>
    <div v-else class="selected-beneficiary">
      <div class="beneficiary-title">Favorecido selecionado</div>
      <div class="beneficiary-name">{{ beneficiary.person.name }}</div>
      <SwapHorizontalCircle 
        class="circle-close" 
        v-b-tooltip.hover title="Mudar favorecido"
        @click="beneficiary = null" 
      />
    </div>
    
    <section v-if="beneficiary?.id">
      <ReportContainer
        :filters="filters"
        :beneficiary="beneficiary"
        :currentPage.sync="currentPage"
        :perPage="perPage"
        :totalRows="totalRows"
        :ruleOptions="ruleOptions"
        :profileOptions="profileOptions"
        :healthPlanOptions="healthPlanOptions"
        :onlendings="onlendings"
        :onlendingIds="onlendingIds"
        @generateExtract="generateExtract"
        @openOnlendingDetailsModal="openOnlendingDetailsModal"
        @changeCurrentPage="changeCurrentPage"
        @changeOnlendingFilter="changeOnlendingFilter"
        :cards="cards"
        :key="reloadOnlendings"
      />
    </section>

    <div v-else>
      <NoFilterResults 
        :message="!hasConfiguration || !ruleOptions.length || !profileOptions.length > 3 ?
          `Configure os 'Perfis', as 'Regras de repasse' e as 'Configurações' para mapear e gerar os relatórios e extratos do repasse médico`:
          ( beneficiary ?
            'Os filtros aplicados não geraram nenhum resultado.' :
            'Selecione um favorecido para visualizar o relatório'
          )"
      />
    </div>

    <GenerateExtractModal
      v-if="selectedOnlendings?.length"
      :beneficiary="beneficiary"
      :selectedOnlendings="selectedOnlendings"
      @change-tab="changeTab"
    />

    <OnlendingDetailsModal
      v-if="onlending"
      :onlending="onlending"
      @closeModal="closeOnlendingDetailsModal"
      @updateOnlending="updateOnlending"
    />

  </div>
</template>
  
<script>
import api from '../api'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'Report',
  components: {
    // BeneficiarySelect: () => import('@/components/General/BeneficiarySelect'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    GenerateExtractModal: () => import('@/modules/onlending/modals/extract/GenerateExtractModal'), 
    NoFilterResults: () => import('@/components/General/noFilterResults'),
    OnlendingDetailsModal: () => import('@/modules/onlending/modals/report/OnlendingDetailsModal'),
    ReportContainer: () => import('@/modules/onlending/components/report/ReportContainer.vue'),
    SwapHorizontalCircle: () => import('@/assets/icons/swap-horizontal-circle.svg'),
    SimpleBeneficiarySelect: () => import('@/modules/onlending/components/report/SimpleBeneficiarySelect.vue')
  },
  created() {
    this.getBeneficiaries()
    this.getOnlendingRules()
    this.getProfiles()
    this.getClinicHealthPlans()
  },
  props:{
    hasConfiguration: Boolean
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      beneficiaries: [],
      ruleOptions: [],
      profileOptions: [],
      healthPlanOptions: [],
      filters: [],    
      beneficiary: null,
      onlendings: [],
      onlendingIds: [],
      cards: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,

      selectedOnlendings: [],
      report: null,
      onlending: null,
      reloadOnlendings: 1,
      firstLoad: true
    }
  },
  methods: {
    parseNumberToMoney,
    async getBeneficiaries() {
      try {
        this.isLoading = true
        this.api.getBeneficiaries(this.clinic.id, { name: '' })
          .then(({data}) => {
            this.beneficiaries = data.map(res => res)
          })
          .finally(() => {this.isLoading = false})
      } catch (error) {
        this.$toast.error(error.message)
        throw new Error(400)
      }
    },
    selectBeneficiary(beneficiary) {
      this.beneficiary = beneficiary
    },
    getOnlendingRules() {
      const isLoading = this.$loading.show()
      api.getOnlendingRulesForFilterByClinicId(this.clinic.id)
      .then(({data}) => {
        this.ruleOptions = data.map(rule => {
          return {
            label: 'Regra #' +rule.number+ ' - ' + rule.name,
            value: rule.id
          }
        })
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },
    getProfiles() {
      const isLoading = this.$loading.show()
      api.getOnlendingProfilesByClinicId(this.clinic.id)
      .then((res) => {
        this.profileOptions = res.data.map(profile => {
          return {
            label: profile.name,
            value: profile.id
          }
        })
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },
    getClinicHealthPlans() {
      const isLoading = this.$loading.show()
      this.api.getAllClinicHealthPlans(this.clinic.id)
      .then((res) => {
        this.healthPlanOptions = res.data.map(clinicHealthPlan => {
          return{
            label: clinicHealthPlan.health_plan.fantasy_name,
            value: clinicHealthPlan.health_plan.id
          }
        })
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },

    changeOnlendingFilter(filters){
      this.filters = filters
      this.currentPage = 1
      this.getOnlendingsByBeneficiaryId()
    },

    getOnlendingsByBeneficiaryId() {      
      if(!this.beneficiary.id) return
      const isLoading = this.$loading.show()
      const data = {
        clinic_id: this.clinic.id,
        beneficiary_id: this.beneficiary?.id,
        filters: this.filters
      }
      api.getOnlendingByBeneficiaryId(this.currentPage, data)
      .then(res => {
        this.onlendings = res.data.data
        this.getValuesToOnlendings();
        this.currentPage = res.data.current_page
        this.perPage = res.data.per_page
        this.totalRows = res.data.total
        
        if(this.currentPage === 1) this.getOnlendingResume()
      })
      .catch(error => {      
        this.$toast.error(error.message)
      })
      .finally(() => {
        isLoading.hide()
      })
    },
    getOnlendingResume() {      
      if(!this.beneficiary.id) return
      const isLoading = this.$loading.show()
      const data = {
        clinic_id: this.clinic.id,
        beneficiary_id: this.beneficiary?.id,
        filters: this.filters
      }
      api.getOnlendingResume(data)
      .then(res => {
        this.onlendingIds = res.data.ids
        this.cards = res.data.cards
      })
      .catch(error => {      
        this.$toast.error(error.message)
      })
      .finally(() => {
        isLoading.hide()
      })
    },
    getValuesToOnlendings(){
      this.onlendings.map(async onlending => {
        let valorPago = 0
        let valorFaturado = 0
        let installment = null
        let payment = null

        if(onlending.item_type !== 'App\\Models\\TissGuide\\Item\\TissGuideItem'){             
          payment = onlending.origin.payments.find(payment => {
            return payment.id === onlending.installment.payment_id;
          })
          const qtdBillItems = onlending.origin.bill_items.length

          if (payment.installments?.length === 1) {
            installment = onlending.installment
            valorPago = (installment.status === 'paid_out' ? onlending.installment.installment_value : 0)
            valorFaturado = qtdBillItems > 1 ? (onlending.origin.final_amount * (onlending.item.final_value * 100 / onlending.origin.final_amount) / 100) : onlending.installment.amount
          } else {             
            if (onlending.payment_type === 'Á vista') {
              let pagos = 0
              let amount = 0
              installment = {
                status: 'Pendente ' + payment.installments.length + '/' + payment.installments.length,
                amount: 0
              }

              payment.installments.forEach(element => {
                if(element.status === 'paid_out'){
                  pagos++
                  amount += element.amount
                }
              });

              if(pagos > 0){
                installment.status = 'Pago ' + pagos + '/'  +  payment.installments.length
                installment.amount = amount
              }
              valorPago = qtdBillItems > 1 ? (installment.amount *((onlending.item.net_value *100) / onlending.origin.final_amount) / 100) : (onlending.installment.status === 'paid_out' ? installment.amount : 0)
              valorFaturado = qtdBillItems > 1 ? (onlending.origin.final_amount * (onlending.item.final_value * 100 / onlending.origin.final_amount) / 100) : onlending.installment.payment.amount
            } else {        
              installment = onlending.installment
              valorPago =  installment?.status === 'paid_out' ? (qtdBillItems > 1 ? (installment.amount *((onlending.item.final_value *100) / onlending.origin.final_amount) / 100) : installment.amount) : 0
              valorFaturado =  qtdBillItems > 1 ? (installment.amount * (onlending.item.final_value * 100 / onlending.origin.final_amount) / 100) : installment?.amount
            }
          }
          
          onlending.valorPago = valorPago
          onlending.valorFaturado = valorFaturado
          onlending.installment = installment
          onlending.payment = payment
        }
        onlending.onlendingSituation = onlending.item_type === 'App\\Models\\TissGuide\\Item\\TissGuideItem' ?
          onlending.item.status ?? 'Pendente' :
          (payment.installments?.length > 1 && onlending.payment_type === 'Á vista' ?
           installment?.status : this.handleSituation(installment?.status))
      })
    },

    updateOnlending(){
      this.getOnlendingsByBeneficiaryId()
    },

    handleSituation(name){
      switch(name) {
        case 'paid_out': return 'Pago'
        case 'pending': return 'Pendente'
        case 'expired': return 'Expirado'
        default: return 'Pendente'
      }
    },

    changeCurrentPage(val){
      this.currentPage = val
    },
    formatDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    generateExtract(props) {
      this.report = props.report
      this.selectedOnlendings = props.selectedOnlendings
      setTimeout(() => {
        this.$bvModal.show('generate-extract-modal')
      }, 300);
    },
    changeTab(data) {
      this.$emit('change-tab', data)
    },

    openOnlendingDetailsModal(onlending){
      this.onlending = onlending
      setTimeout(() => {
        this.$bvModal.show('onlending-details-modal')
      }, 300);
    },
    closeOnlendingDetailsModal(){
      this.onlending = {}
    }
  },
  watch: {
    beneficiary: function(val) {
      if(val?.id && !this.firstLoad){
        this.currentPage = 1
        this.getOnlendingsByBeneficiaryId()
        this.getOnlendingResume()
      }
      this.firstLoad = false
    },
    currentPage: {
      handler(newVal, oldVal) {
        if(newVal !== oldVal && this.beneficiary) this.getOnlendingsByBeneficiaryId()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;

  .title {
    font-family: 'Nunito Sans';
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    color: var(--type-active);
    display: flex;
    align-items: center;

    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
  }
}
.beneficiary-title {
  font-family: "Nunito Sans";
  font-weight: 600;
  font-size: 16px;
  color: var(--type-active);
}
.beneficiary-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--neutral-300);
  padding: 1rem;
  cursor: pointer;
}
.selected-beneficiary {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  gap: 5px;
}
.beneficiary-name {
  color: var(--blue-500);
}
.circle-close {
  cursor: pointer;
}
.select-beneficiary {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-left: 3px;
}
</style>